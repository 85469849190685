<template>
    <modal
        :header="propertyId ? $t('lists.editButton.property') : $t('lists.addButton.property')"
        width="632"
        v-model="model"
    >
        <form v-if="model" class="modal__content property__modal form" ref="form" @submit.prevent>
            <progress-circular class="modal__spinner spinner" v-if="isLoading"></progress-circular>
            <template v-else>
                <template v-for="(field, key) in currentState.fields">
                    <form-item
                        class="modal__input"
                        :key="key"
                        :class="{
                            [field.props.class]: true,
                            '-third-width': key === 'usage' && currentState.usage === 'catalogs',
                        }"
                        v-if="checkIsShown(key)"
                        v-bind="field.props"
                        :name="key"
                    >
                        <component
                            v-if="key === 'catalogIds'"
                            :is="field.component"
                            class="property-modal__catalog-search"
                            item-text="title"
                            item-value="id"
                            :items="catalogs"
                            v-model="currentState[key]"
                            v-bind="field.props || {}"
                            @update-field="onUpdateFieldValue"
                            :name="key"
                            v-on="getFieldEvents(field.events)"
                        ></component>
                        <component
                            v-else-if="key === 'values'"
                            :is="field.component"
                            class="modal__combobox"
                            :value="currentState.values"
                            item-text="title"
                            item-value="id"
                            v-bind="field.props || {}"
                            :items="currentState.values"
                            :itemsType="currentState.type"
                            :name="key"
                            v-on="getFieldEvents(field.events)"
                        ></component>
                        <component
                            v-else
                            :is="field.component"
                            v-model="currentState[key]"
                            v-bind="field.props || {}"
                            @update-field="onUpdateFieldValue"
                            :name="key"
                            v-on="getFieldEvents(field.events)"
                        />
                    </form-item>
                </template>
            </template>
        </form>
        <div class="form__input">
            <Button
                class="modal__save-btn"
                :disabled="!isSaveAllowed"
                icon="check"
                @click="propertyId ? onUpdateProperty() : onCreateProperty()"
                >{{ $t('entities.save') }}</Button
            >
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Property from '@/entities/property/Property';
import PropertyService from '@/services/PropertyService';
import ComboboxMultiple from '@/components/form/controls/ComboboxMultiple';
import FormItem from '@/components/form/item';
import validateField from '@/helpers/validator';
import SelectMultiple from '@/components/form/controls/SelectMultiple';
import { debounce, cloneDeep, isEqual } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import CatalogService from '@/services/CatalogService';
import InputIcon from '@/components/form/controls/InputIcon';

export default {
    name: 'PropertyModal',
    mixins: [proxyModelMixin],
    components: { FormItem, Modal, ProgressCircular, ComboboxMultiple, Button, InputIcon, SelectMultiple },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
        propertyId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isValid: false,
            currentState: new Property(),
            fieldKeys: Object.keys(new Property().fields),
            catalogs: [],
            initialState: null,
            isLoading: false,
            typeError: null,
        };
    },
    methods: {
        async getProperty() {
            const [error, result] = await PropertyService.getOne(this.propertyId);
            if (error) {
                error.notify();
            }
            this.currentState = new Property(result);
            this.initialState = cloneDeep(this.currentState);
        },
        async getCatalogs() {
            const [error, result] = await CatalogService.getAll();
            if (error) {
                error.notify();
            }
            this.catalogs = result;
        },
        initEmptyForm() {
            this.isLoading = true;
            setTimeout(() => (this.isLoading = false), 300);
            this.currentState = new Property();
            this.initialState = cloneDeep(this.currentState);
        },
        checkIsShown(key) {
            if (key === 'catalogIds' && this.currentState.usage !== 'catalogs') {
                return false;
            }
            return true;
        },

        async onCreateProperty() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;

            const [error, result] = await PropertyService.createOne(this.currentState.data);
            if (error) this.failedHandler(result, error);
            else {
                this.$emit('action-success');
                this.model = false;
                EventEmitter.trigger('show-noty', {
                    type: 'success',
                    text: this.$tc('notifications.created', 1, { entity: this.$tc('entities.property.title', 1) }),
                });
                this.currentState = new Property();
            }
        },
        async onUpdateProperty() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await PropertyService.updateOne(this.currentState.data);
            if (error) this.failedHandler(result, error);
            else {
                this.$emit('action-success');
                this.model = false;
                EventEmitter.trigger('show-noty', {
                    type: 'success',
                    text: this.$tc('notifications.updated', 1, { entity: this.$tc('entities.property.title', 1) }),
                });
                this.currentState = new Property();
            }
        },

        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.currentState.fields);
            fieldKeys.forEach((key) => {
                let errors;
                if (key === 'catalogIds') {
                    errors =
                        this.currentState.usage === 'catalogs'
                            ? validateField(this.currentState[key], this.currentState.fields[key].props.rules)
                            : [];
                } else {
                    errors = validateField(this.currentState[key], this.currentState.fields[key].props.rules);
                }
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        failedHandler() {},

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.currentState[name], this.currentState.fields[name].props.rules);
            this.$set(this.currentState.fields[name].props, 'errors', errors);
        }, 1000),

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.currentState);
            }
            return fieldEvents;
        },
    },
    computed: {
        isSaveAllowed() {
            if (this.initialState) {
                return !isEqual(this.initialState.data, this.currentState.data);
            }
            return false;
        },
    },
    watch: {
        async value(val) {
            if (!val) return;
            this.isLoading = true;
            await this.getCatalogs();
            if (this.propertyId) {
                await this.getProperty();
                this.isLoading = false;
            } else this.initEmptyForm();
        },
    },
};
</script>

<style lang="scss">
.property-modal {
    &__catalog-search.search-form {
        width: auto !important;
    }
}
</style>
